<template>
  <div class="form">
    <div class="form-title">
      <span>{{ $t("form_Q1") }}</span>
      <span class="danger-text">{{ $t("form_require") }}</span>
      <span class="ml-4 description-many">{{ $t("form_many") }}</span>
    </div>

    <section class="form-row">
      <div v-for="(item, index) in options" :key="index" class="form-col">
        <v-checkbox
          v-model="checkList"
          :label="item"
          color="#132d7a"
          :value="item"
          @change="setData()"
          style="width: 100%"
        ></v-checkbox>
      </div>
    </section>
  </div>
</template>

<script>
import { options1 } from "./answer";

export default {
  name: "Form1",
  data() {
    return {
      checkList: [],
      options: options1,
    };
  },
  mounted() {
    let sessionItem = JSON.parse(sessionStorage.getItem("q1"));

    if (sessionItem) {
      this.checkList = sessionItem;
    }
  },
  methods: {
    setData() {
      if (this.checkList.length) {
        sessionStorage.setItem("q1", JSON.stringify(this.checkList));
      } else {
        sessionStorage.setItem("q1", JSON.stringify(""));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 20px;

  .form-title {
    font-size: max(16px, 1.8vw);
    font-weight: bold;
    color: #132d7a;
    text-align: center;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    height: 90%;
  }

  .form-col {
    min-width: 150px;
    height:3vw;
  }
}
</style>
